//END POINT OF Api's
export const login = "login";
export const tickets = "tickets";
export const show_SingleTicket = "show-ticket";
export const edit_ticket = "edit-ticket";
export const delete_ticket = "delete-ticket";
export const status_update = "status-update-tickets";
export const show_ticket = "tickets";
export const store_tickets = "store-tickets";
export const get_export = "get-export";
export const get_teams = "get-teams";
export const get_organizations = "get-organizations";
export const get_organization = "get-organization";
export const get_users = "get-users";
export const token = "token";
export const user = "token";
export const store_comment = "store-comment";
export const store_organizationoken = "store-organization";
export const update_organization = "update-organization";
export const delete_organization = "delete-organization";
export const store_teams = "store-teams";
export const store_users = "store-users";
export const delete_users = "delete-users";
export const update_users = "update-users";
export const show_users = "show-users";
export const refreshToken = "refreshToken";
export const get_form_main_teams = "get-form-main-teams?";
export const get_all_team = "get-all-team";
export const get_teamTicket_graph = "get-teamTicket-graph";
export const user_tour = "user-tour";
export const get_form_sub_teams = "get-form-sub-teams";
export const get_form_sub_multi = "get-form-sub-multi";
export const delete_teams = "delete-teams";
export const update_teams = "update-teams";
export const get_single_team = "show-teams";
export const getstatus = "get-status";
export const getpriority = "get-priority";
export const getdynamicfields = "get-dynamicfields?";
export const getUserFormDynamicfields = "user-form-dynamicfields";
export const getUserOrganizationDynamicfields =
  "user-orgnization-dynamicfields";
export const getalldynamicfields = "get-alldynamicfields";
export const get_form_dynamic_fields = "get-form-dynamics?";
export const store_dynamicfields = "store-dynamicfields";

export const remove_dynamic_field_value = "remove-dynamicFieldValue";
export const add_dynamic_field_value = "add-dynamicFieldValue";
export const update_dynamicfields = "update-dynamicfields";
export const single_dynamicfields = "single-dynamicfields";
export const delete_dynamicfields = "delete-dynamicfields";
export const get_form_organizations = "get-form-organizations";
export const get_form_OrganizationWithoutToken =
  "get-form-OrganizationWithoutToken";
export const team_dynamicfields = "team-dynamicfields";
export const get_chartData = "get-chartData";
export const get_TeamGraph = "get-teamTicket";
export const delete_comment = "delete-comment";
export const get_tag_trending = "get-tag-trending?";
export const assign_ticket = "assign-ticket";
export const escalate_ticket = "escalate-ticket";
export const my_ticket = "my-ticket";
export const user_profile = "user-profile";
export const responsible_ticketUsers = "responsible-ticketUsers";
export const forgot_password = "forgot-password";
export const change_password = "change-password";
export const store_requester = "store-requester";
export const get_requester = "get-requester";
export const delete_requeste = "delete-requester";
export const show_requester = "show-requester";
export const user_notifi = "user-notification";
export const getNotification = "notificationData";
export const get_form_teamWithoutToken = "get-form-teamWithoutToken?";
export const get_form_main_teams_multi = "get-form-main-teams-create";
export const store_support = "store-support";
export const user_dynamicFields = "user-dynamicFields";
export const get_logs = "get-logs";
export const get_tickets_count = "get-tickets-count";
export const organization_rules = "organization-rules";
export const get_organization_rules = "get-organization-rules";
export const delete_organization_rules = "delete-organization-rules";
export const get_dynamic_fields = "get-dynamic-fields";
export const get_dynamic_field_values = "get-dynamic-field-values";
export const get_region = "get-dynamic";
export const store_solveComment = "store-solveComment";
export const get_solveComment = "get-solveComment";
export const show_solveComment = "show-solveComment";
export const update_solveComment = "update-solveComment";
export const delete_solveComment = "delete-solveComment";
export const get_solveCommentTeam = "get-solveCommentTeam";
export const location = "location";
export const region = "region";
export const attachments = "attachments";
export const logout = "logout";
export const get_user_email_suggestion = "get-user-email-suggestion";
export const get_report = "get-report";
export const dashboardApi = "dashboardApi";
export const store_escalation_Matrix = "store-escalation-Matrix";
export const get_escalation_Matrix = "get-escalation-Matrix";
export const update_escalation_Matrix = "update-escalation-Matrix";
export const user_escalation_Matrix = "user-escalation-Matrix";
export const list_escalation_Matrix = "list-escalation-Matrix";
export const delete_escalation_Matrix = "delete-escalation-Matrix";
export const ticket_escalationUsers = "ticket-escalationUsers";
export const ticket_escalationUsers_goLang = "tickets/escalationUsers";
export const ticket_escalationCheck = "ticket-escalationCheck";
export const user_preference = "user-preference";
export const update_user_preference_golang = "tickets/update-user-preference";
export const get_user_preference = "get-user-preference";
export const user_notification_seen = "user-notification-seen";
export const user_notification_all_seen = "user-notification-all-seen";
export const user_notification_data = "user-notification-data";
export const getDfWithOutPagination = "get-dynamicfields-without-pagination?";
export const refreshUserToken = "refreshUserToken";
export const forbidden = "forbiddenRoute-logs";
export const ticketStatusGraph = "ticketStatusGraph";
export const allTicketGraph = "allTicketGraph";
export const ticketVolumeGraph = "ticketVolumeGraphWithCloserRate";
export const ticketFirstResponseTime = "ticketFirstResponceTime";
export const ticketResolutionTime = "ticketResolutionTime";
export const openCloseRatio = "openCloseRatio";
export const topOrganization = "topOrginization";
export const teamTicketChartGraph = "teamTicketChartGraph";
export const rolesList = "roles";
export const rolesCreate = "role/create";
export const roleUpdate = "role/update";
export const roleDelete = "role/delete";
export const roleShow = "role/show";
export const modulesList = "modules";
export const modulesCreate = "module/create";
export const moduleUpdate = "module/update";
export const moduleDelete = "module/delete";
export const permissionList = "permissions";
export const permissionCreate = "permission/create";
export const permissionAssign = "permission/assign";
export const permissionDelete = "permission/delete";
export const permissionShow = "permission/show";
export const permissionUpdate = "permission/update";
export const getOrgPermissions = "orgPermissions";
export const getTeamMembers = "getTeamMembers";
export const facility = "facility";
export const store_sentinel_requester = "store-sentinel-requester";
export const activate_user_account = "activate-user-account";
export const golangChartData = "chartData";
export const listFAQs = "faqs";
export const createFAQ = "faqs/create";
export const workflow_check = "workflow/check/";
export const getTicket_workflow = "workflow/getTicketWorkflow/";
export const store_workflowAction = "workflow/storeStepData/";
export const dynamicField_wo = "workflow/dynamicField/";
export const checkWorkflowAction = "workflow/action/";
export const phcTicketStatusGraph = "phcTicketStatusGraph";
